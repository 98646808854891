/* istanbul ignore file */
/* eslint-disable */
/* prettier-ignore */
import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';


export const roundNumber = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

/**
 * Filters an array of objects based on the passed properties
 * @param suggestions
 * @param val
 * @param properties
 */
export const filterSuggestions = (suggestions: any[], val: string | undefined, properties: string[]) => {
  if (!val) {
    return [];
  }
  const inputValue = val.trim().toLowerCase();
  const inputLength = inputValue?.length;

  return inputLength === 0
    ? []
    : suggestions.filter(item => {
        const condition = properties.map(p => item[p.trim()].toLowerCase().search(inputValue) !== -1);
        return condition.includes(true);
      });
};

/**
 * Sum an array of objects by a property
 * @param array
 * @param property
 */
export const sumArrayObjectValues = (array: any[], property: string) =>
  array.reduce((prev: number, cur: any) => prev + parseFloat(cur[property].toString()), 0) || 0;

export const duration = (seconds: number) => formatDistance(0, seconds * 1000, { includeSeconds: true, locale: es });

/**
 * Generates a random number from 0 to max
 * @param max
 */
export const randomNumber = (max = 10000000) => Math.floor(Math.random() * max);

export const uuid = (): string => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const dateForHuman = (unixTimestamp: number) => {
  const parsedDate = new Date(unixTimestamp * 1000);

  const year = parsedDate.getFullYear();
  const day = String(parsedDate.getDate()).padStart(2, '0');
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');


  const hours = String(parsedDate.getHours()).padStart(2, '0');
  const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const dateFormat = (isoDate: string | Date) => {
  if (!isoDate) return '';

  const parsedDate = typeof isoDate === 'string' ? new Date(isoDate) : isoDate;

  if (isNaN(parsedDate.getTime())) return '';

  const day = String(parsedDate.getDate()).padStart(2, '0');
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const year = parsedDate.getFullYear();

  const hours = String(parsedDate.getHours()).padStart(2, '0');
  const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};
